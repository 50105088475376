import AddIcon from "@mui/icons-material/Add";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import DeleteIcon from "@mui/icons-material/Delete";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
} from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import { NO_VALUE, USER_ROLES } from "constant";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { dispatch } from "store";
import {
  getAssetsSubType,
  getAssetsType,
  getConsultantSMList,
  getConsultantTesterList,
  getScanDetails,
} from "store/slices/scanReducer";
import {
  deleteVulnerabilityToScan,
  downlaodNessustemplate,
  downlaodNessusTemplateSuccess,
  getClientStatusValue,
  getConsultantStatusValue,
  getRiskRatingValue,
  getScanVulnerabilities,
  smartCloseVulnerabilities,
  statusSM,
  statusTester,
  submitToSM,
  submitToTester,
  updateVulnerabilityStatus,
  uploadNessusCsv,
} from "store/slices/vulnerabilityReducer";
import Delete from "ui-component/delete";
import DialogBox from "ui-component/dialogbox";
import ErrorDisplay from "ui-component/errorList";
import VmTable from "ui-component/table";
import {
  downloadTemplateFile,
  getChipDesign,
  handleUserRoles,
} from "utils/helpers";
import GenericFilterBar from "views/Common/genericFilterBar";
import { DataListType, rowsPerPageOptions } from "../../constant";
import ActionModal from "./actionModal";
import NessusUpload from "./nessusUpload";

const ActionButton = (props) => {
  const [anchorEl, setAnchorEl] = useState({
    open: null,
    close: null,
    falsePositive: null,
    uploadFiles: null,
  });
  const params = useParams();
  const { type } = useParams();
  const clientOrgId = sessionStorage.getItem("clientOrgId");
  const { userRoles } = useSelector((state) => state.menu);
  const { downlaodNessusTemplateResponse } = useSelector(
    (state) => state?.vulnerabilityState
  );
  const scanDetails = props?.scanDetails;
  const handleDeleteVulnerability = () => {
    props?.handleDeleteVulnerabilityDialog();
  };

  const handleClick = (event) => {
    setAnchorEl({ [event.currentTarget?.name]: event.currentTarget });
  };
  const handleClose = () => {
    setAnchorEl({
      open: null,
      close: null,
      falsePositive: null,
      uploadFiles: null,
    });
  };

  const handleStatus = (status, validRoles) => {
    if (props?.selectedIds?.length > 0) {
      const payload = {
        status: status,
        clientOrgId,
        vulnerabilityIdList: props?.selectedIds,
      };
      props?.setSelectedIds([]);
      if (
        handleUserRoles(userRoles, USER_ROLES?.CO_SECURITY_MANAGER) &&
        validRoles
      ) {
        dispatch(statusSM(payload)).then(() => {
          dispatch(
            getScanVulnerabilities({ scanId: params?.scanId, clientOrgId, page: props?.page, size: props?.rowsPerPage })
          );
          handleClose();
        });
      } else {
        dispatch(statusTester(payload)).then(() => {
          dispatch(
            getScanVulnerabilities({ scanId: params?.scanId, clientOrgId, page: props?.page, size: props?.rowsPerPage })
          );
          handleClose();
        });
      }
    }
  };

  // const handleSubmit = () => {
  //   const payload = {
  //     idList: [props?.selectedIds],
  //   };
  //   if (props?.selectedIds?.length > 0) {
  //     (  if (handleUserRoles(userRoles, USER_ROLES?.CO_SECURITY_MANAGER))
  //       ? dispatch(submitToTester(clientOrgId, payload))
  //       : dispatch(submitToSM(clientOrgId, payload))
  //     ).then(() => {
  //       props?.setSelectedIds([]);
  //       dispatch(getScanVulnerabilities(params?.scanId, clientOrgId));
  //     });
  //   }
  // };
  const handleSubmit = (role) => {
    const payload = {
      idList: props?.selectedIds,
    };
    if (props?.selectedIds?.length > 0) {
      if (role === "sm") {
        dispatch(submitToTester(clientOrgId, payload)).then(() => {
          props?.setSelectedIds([]);
          dispatch(
            getScanVulnerabilities({ scanId: params?.scanId, clientOrgId, page: props?.page, size: props?.rowsPerPage })
          );
        });
      } else {
        dispatch(submitToSM(clientOrgId, payload)).then(() => {
          props?.setSelectedIds([]);
          dispatch(
            getScanVulnerabilities({ scanId: params?.scanId, clientOrgId, page: props?.page, size: props?.rowsPerPage })
          );
        });
      }
    }
  };

  // const handleDownloadTemplate = () => {
  //   dispatch(downlaodNessustemplate());
  // };

  // // useEffect(() => {
  // //   if (Object.keys(downlaodNessusTemplateResponse)?.length > 0) {
  // //     const { fileContent, fileName } = downlaodNessusTemplateResponse;
  // //     downloadTemplateFile(fileContent, fileName);
  // //     return () => {
  // //       dispatch(downlaodNessusTemplateSuccess({}));
  // //     };
  // //   }
  // // }, [downlaodNessusTemplateResponse]);

  const handleRoles = (role) => {
    if (handleUserRoles(userRoles, role)) {
      return true;
    }
    return false;
  };

  // const handleFileChange = (event) => {
  //   const selectedFile = event?.target?.files[0];
  //   if (!selectedFile) {
  //     setFile(null);
  //     return;
  //   }
  //   setFile(selectedFile);
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     // Set the file and its Data URL in the state
  //     setFile({
  //       dataUrl: e.target.result,
  //       name: selectedFile?.name,
  //       type: selectedFile?.type,
  //     });
  //   };
  //   reader.readAsDataURL(selectedFile);
  // };

  // const vulnerabilityMenus = () => {
  //   return (
  //     <>
  //       <MenuItem aria-label="actions">
  //         <Button
  //           variant="text"
  //           component="label"
  //           size="small"
  //           onClick={() => handleStatus("O", true)}
  //         >
  //           Selected Record
  //         </Button>
  //       </MenuItem>
  //       <MenuItem aria-label="actions">
  //         <Button
  //           variant="text"
  //           component="label"
  //           size="small"
  //           onClick={() => handleStatus("O")}
  //         >
  //           All
  //         </Button>
  //       </MenuItem>
  //     </>
  //   );
  // };
  const onClickUpload = (normalUpload) => {
    props.setOpenNessusUploadDialog(true);
    props.setNormalUpload(normalUpload);
  };

  return (
    <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }}>
      <Grid xs={12} sx={{ display: "flex", gap: "5px" }}>
        <GenericFilterBar {...props} />
      </Grid>
      <Grid sx={{ display: "flex", justifyContent: "end" }}>
        <IconButton
          variant="contained"
          title="Actions"
          size="large"
          aria-label="live customize"
          name="open"
          onClick={() => props.setVisibleActionModal(true)}
          color="inherit"
        >
          <FileOpenIcon />
        </IconButton>
        <Link to={`/scan/${params?.scanId}/vulnerability/add`}>
          {scanDetails?.displayScanVulnerabilityAction?.addVulnerability ? (
            <IconButton
              title="Add Vulnerability"
              variant="contained"
              size="large"
              color="inherit"
              aria-label="live customize"
              sx={{
                color: "grey",
              }}
              onClick={() => props.setOpen(true)}
            >
              <AddIcon />
            </IconButton>
          ) : (
            <></>
          )}
        </Link>

        <Link to={`/scan/${params?.scanId}/vulnerability/pull`}>
          {scanDetails?.displayScanVulnerabilityAction?.pullVulnerability ? (
            <IconButton
              title="Pull Vulnerability"
              variant="contained"
              size="large"
              color="inherit"
              aria-label="live customize"
              sx={{
                color: "grey",
              }}
            >
              <SystemUpdateAltIcon />
            </IconButton>
          ) : (
            <></>
          )}
        </Link>
        <>
          {scanDetails?.displayScanVulnerabilityAction?.uploadNessusCSV && (
            <IconButton
              aria-label="actions"
              onClick={(e) => handleClick(e)}
              title="Upload Nessus File"
              color="inherit"
              name="uploadFiles"
            >
              <FileUploadIcon />
            </IconButton>
          )}
          <Menu
            id="menu-simple-card"
            anchorEl={anchorEl?.uploadFiles}
            keepMounted
            open={Boolean(anchorEl?.uploadFiles)}
            onClose={handleClose}
            variant="selectedMenu"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <>
              <>
                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    onClick={() => onClickUpload(false)}
                  >
                    Nessus Upload
                  </Button>
                </MenuItem>

                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    onClick={() => onClickUpload(true)}
                  >
                    Normal Upload
                  </Button>
                </MenuItem>
              </>
            </>
          </Menu>
          {/* <Input
          id="fileInput"
          type="file"
          style={{ display: "none" }}
          // onChange={handleFileChange}
          accept="image/*"
        />
        <label htmlFor="fileInput">
          <IconButton
            size="small"
            component="span"
            variant="outlined"
            color="inherit"
            title="Upload Files"
            name="uploadFiles"
          >
            <FileUploadIcon />
          </IconButton>
        </label> */}
        </>
        {/* ) : (
        <></>
      )} */}
        {/* <Menu
          id="menu-simple-card"
          anchorEl={anchorEl?.uploadFiles}
          keepMounted
          open={Boolean(anchorEl?.uploadFiles)}
          onClose={handleClose}
          variant="selectedMenu"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <>
            {/* {scanDetails?.displayScanVulnerabilityAction?.uploadNessusCSV ? (
            <MenuItem aria-label="actions" for="fileInput">
              <Button
                onChange={props.uploadCsv}
                variant="text"
                component="label"
                size="small"
                sx={{
                  background: "#ffffff",
                  color: "black",
                }}
              >
                Nessus V2 - CSV
                <input id="fileInput" type="file" hidden />
              </Button>
            </MenuItem>
          ) : (
            <></>
          )} */}
        {/* </> */}
        {/* </Menu> */}
        {/* {scanDetails?.displayScanVulnerabilityAction?.showMarkAsOpen ? (
          <IconButton
            disabled={!props?.selectedIds?.length}
            variant="contained"
            title="Open"
            size="large"
            aria-label="live customize"
            name="open"
            onClick={(e) =>
              userRoles?.length > 1 ? handleClick(e) : handleStatus("O", true)
            }
            color="inherit"
          >
            <FileOpenIcon />
          </IconButton>
        ) : (
          <></>
        )} */}
        <Menu
          id="menu-simple-card"
          anchorEl={anchorEl?.open}
          keepMounted
          open={Boolean(anchorEl?.open)}
          onClose={handleClose}
          variant="selectedMenu"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <>
            <>
              {handleRoles(USER_ROLES.CO_SECURITY_MANAGER) ? (
                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    sx={{
                      background: "#ffffff",
                      color: "black",
                    }}
                    onClick={() => handleStatus("O", true)}
                  >
                    For Security Manager
                  </Button>
                </MenuItem>
              ) : (
                ""
              )}
              {handleRoles(USER_ROLES.CO_TESTER) ? (
                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    sx={{
                      background: "#ffffff",
                      color: "black",
                    }}
                    onClick={() => handleStatus("O")}
                  >
                    For Tester
                  </Button>
                </MenuItem>
              ) : (
                ""
              )}
            </>
          </>
        </Menu>
        {/* {scanDetails?.displayScanVulnerabilityAction?.showMarkAsClose ? (
          <IconButton
            disabled={!props?.selectedIds?.length}
            variant="contained"
            title="Close"
            size="large"
            name="close"
            aria-label="live customize"
            onClick={(e) =>
              userRoles?.length > 1 ? handleClick(e) : handleStatus("C", true)
            }
            color="inherit"
          >
            <CancelPresentationIcon />
          </IconButton>
        ) : (
          <></>
        )} */}
        <Menu
          id="menu-simple-card"
          anchorEl={anchorEl?.close}
          keepMounted
          open={Boolean(anchorEl?.close)}
          onClose={handleClose}
          variant="selectedMenu"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <>
            <>
              {handleRoles(USER_ROLES.CO_SECURITY_MANAGER) ? (
                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    sx={{
                      background: "#ffffff",
                      color: "black",
                    }}
                    onClick={() => handleStatus("C", true)}
                  >
                    For Security Manager
                  </Button>
                </MenuItem>
              ) : (
                ""
              )}
              {handleRoles(USER_ROLES.CO_TESTER) ? (
                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    sx={{
                      background: "#ffffff",
                      color: "black",
                    }}
                    onClick={() => handleStatus("C")}
                  >
                    For Tester
                  </Button>
                </MenuItem>
              ) : (
                ""
              )}
            </>
          </>
        </Menu>
        {/* {scanDetails?.displayScanVulnerabilityAction?.showMarkAsFalsePositive ? (
          <IconButton
            disabled={!props?.selectedIds?.length}
            variant="contained"
            title="False Postive"
            size="large"
            name="falsePositive"
            aria-label="live customize"
            onClick={(e) =>
              userRoles?.length > 1 ? handleClick(e) : handleStatus("FP", true)
            }
            color="inherit"
          >
            <ErrorOutlineIcon />
          </IconButton>
        ) : (
          <></>
        )} */}
        <Menu
          id="menu-simple-card"
          anchorEl={anchorEl?.falsePositive}
          keepMounted
          open={Boolean(anchorEl?.falsePositive)}
          onClose={handleClose}
          variant="selectedMenu"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <>
            <>
              {handleRoles(USER_ROLES.CO_SECURITY_MANAGER) ? (
                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    sx={{
                      background: "#ffffff",
                      color: "black",
                    }}
                    onClick={() => handleStatus("FP", true)}
                  >
                    For Security Manager
                  </Button>
                </MenuItem>
              ) : (
                ""
              )}
              {handleRoles(USER_ROLES.CO_TESTER) ? (
                <MenuItem aria-label="actions">
                  <Button
                    variant="text"
                    component="label"
                    size="small"
                    sx={{
                      background: "#ffffff",
                      color: "black",
                    }}
                    onClick={() => handleStatus("FP")}
                  >
                    For Tester
                  </Button>
                </MenuItem>
              ) : (
                ""
              )}
            </>
          </>
        </Menu>

        {scanDetails?.displayScanVulnerabilityAction?.showMarkAsWIP ? (
          <>
            {/* <IconButton
              disabled={!props?.selectedIds?.length}
              variant="contained"
              title="Work-in-Progress"
              size="large"
              aria-label="live customize"
              onClick={() => handleStatus("WIP")}
              color="inherit"
            >
              <HourglassBottomIcon />
            </IconButton> */}
            {scanDetails?.displayScanVulnerabilityAction?.showSubmitToSM ? (
              <IconButton
                disabled={!props?.selectedIds?.length}
                variant="contained"
                title="Submit To Security Manager"
                size="large"
                aria-label="live customize"
                onClick={() => handleSubmit("tester")}
                color="inherit"
              >
                <AssignmentIndIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            {/* {scanDetails?.displayScanVulnerabilityAction?.showMarkAsRetest ? (
              <IconButton
                disabled={!props?.selectedIds?.length}
                variant="contained"
                title="Rework"
                size="large"
                aria-label="live customize"
                onClick={() => handleStatus("RT", true)}
                color="inherit"
              >
                <ReplayIcon />
              </IconButton>
            ) : (
              <></>
            )} */}
            {/* {scanDetails?.displayScanVulnerabilityAction?.showMarkAsReview ? (
              <IconButton
                disabled={!props?.selectedIds?.length}
                variant="contained"
                title="Review"
                size="large"
                aria-label="live customize"
                onClick={() => handleStatus("RV", true)}
                color="inherit"
              >
                <ReviewsIcon />
              </IconButton>
            ) : (
              <></>
            )} */}
            {scanDetails?.displayScanVulnerabilityAction?.showAssignToTester ? (
              <IconButton
                disabled={!props?.selectedIds?.length}
                variant="contained"
                title="Assign To Tester"
                size="large"
                aria-label="live customize"
                onClick={() => handleSubmit("sm")}
                color="inherit"
              >
                <AssignmentIndIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </>
        )}
        {scanDetails?.displayScanVulnerabilityAction?.deleteVulnerability ? (
          <IconButton
            disabled={!props?.selectedIds?.length}
            variant="contained"
            title="Delete"
            size="large"
            aria-label="live customize"
            onClick={handleDeleteVulnerability}
            color="inherit"
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <></>
        )}
        {/* <IconButton
          title="Template For Reference"
          variant="contained"
          size="large"
          color="inherit"
          onClick={() => handleDownloadTemplate()}
        >
          <UploadFileIcon />
        </IconButton> */}
        {scanDetails?.displayVulnerabilityField?.smartClose ? (
          <IconButton
            variant="contained"
            title="Auto Close"
            size="large"
            aria-label="live customize"
            onClick={() => props.handleAutoClose()}
            color="inherit"
          >
            <AutoModeIcon />
          </IconButton>
        ) : (
          <></>
        )}

        {/* <Button
        size="large"
        variant="contained"
        sx={{
          boxShadow: theme.customShadows.primary,
          ":hover": {
            boxShadow: "none",
          },
        }}
        onClick={handleClick}
        aria-label="actions"
        endIcon={<ArrowDropDownIcon />}
      >
        Actions
      </Button>

      <Menu
        id="menu-simple-card"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        variant="selectedMenu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {type === "manager" && (
          <>
            <MenuItem onClick={() => handleStatusSM("RV")}>
              {" "}
              Review
            </MenuItem>
            <MenuItem onClick={() => handleStatusSM("O")}>
              {" "}
              Open
            </MenuItem>
            <MenuItem onClick={() => handleStatusSM("C")}>
              {" "}
              Close
            </MenuItem>
            <MenuItem onClick={() => handleStatusSM("RT")}>
              {" "}
              Retest
            </MenuItem>
            <MenuItem onClick={handleSubmitToTester}>
              {" "}
              Assign To Tester
            </MenuItem>
          </>
        )}

        {type !== "manager" && (
          <>
            <MenuItem onClick={() => handleStatusTester("O")}>
              {" "}
              Open
            </MenuItem>
            <MenuItem onClick={() => handleStatusTester("C")}>
              {" "}
              Close
            </MenuItem>
            <MenuItem onClick={() => handleStatusTester("WIP")}>
              {" "}
              Work-in-Progress
            </MenuItem>
            <MenuItem onClick={handleSubmitToSM}>
              {" "}
              Submit To Security Manager
            </MenuItem>
          </>
        )}
      </Menu> */}
      </Grid>
    </Grid>
  );
};

const ScanVulnerabilities = (props) => {
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  const location = useLocation()?.pathname?.split("/")[1];
  const navigate = useNavigate();
  const params = useParams();
  const [selectedIds, setSelectedIds] = useState();
  const [valueLabel, setValueLabel] = useState("all");
  const [open, setOpen] = useState("all");
  const [openDeleteAssetDialog, setOpenDeleteVulnerabilityDialog] = useState();
  const [showError, setShowError] = useState();
  const [openNessusUploadDialog, setOpenNessusUploadDialog] = useState(false);
  const {
    scanVulnerabilities,
    uploadNessusReponse,
    consultantStatusValue,
    riskRatingValue,
    clientStatusValue,
  } = useSelector((state) => state?.vulnerabilityState);
  const {
    assetsTypes,
    assetSubTypes,
    allScans,
    scanTypes,
    scanSubTypes,
    consultantClientOrganizationList,
    allScanStatus,
    consultantSMList,
    consultantTesterList,
    downloadScanData,
  } = useSelector((state) => state?.scansState);
  const { setDataloading } = useSelector((state) => state?.userState);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState({
    assetType: "",
    assetSubType: "",
    assetURL: "",
    assetId: "",
  });
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedConsultantStatus, setSelectedConsultantStatus] = useState([]);
  const [selectedClientStatus, setSelectedClientStatus] = useState([]);
  const [selectedComplianceStatus, setSelectedComplianceStatus] = useState({});
  const [selectedRiskRating, setSelectedRiskRating] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [normalUpload, setNormalUpload] = useState(false);
  const [visibleActionModal, setVisibleActionModal] = useState(false);
  const [selectedSecurityManagerName, setSelectedSecurityManagerName] =
    useState([]);
  const [selectedTesterName, setSelectedTesterName] = useState([]);

  const {
    OrgAssetsList,
    uploadAsset,
    exportAssetDataRes,
    assetGroupList,
    assetGroupManager,
    OrgAssetsListForFilter,
  } = useSelector((state) => state?.assetsState);

  const [roleList, setRoleList] = useState([]);
  const [statusDetail, setStatusDetail] = useState({
    status: "",
    markFor: "",
    data: "",
  });

  const clientOrgId = sessionStorage.getItem("clientOrgId");
  const { userRoles } = useSelector((state) => state.menu);
  const { downlaodNessusTemplateResponse } = useSelector(
    (state) => state?.vulnerabilityState
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "vulRefNumber",
        header: "Vulnerability Ref Code",
        muiTableBodyCellProps: ({ row }) => ({
          onClick: (event) => {
            navigate(`/vulnerabilities/${row?.original?.scanVulnerabilityId}`);
          },
          sx: {
            cursor: "pointer",
            // color: theme.palette.secondary.main,
            textDecoration: "underline",
          },
        }),
      },
      {
        accessorKey: "assetName",
        header: "Asset Name",
      },
      {
        accessorKey: "assetTypeName",
        header: "Asset Type",
      },
      {
        accessorKey: "smartClose",
        header: "Smart Close",
        Cell: ({ row }) => <>{row?.original?.smartClose ? "Yes" : "No"}</>,
      },
      {
        accessorKey: "assetSubTypeName",
        header: "Asset Sub Type",
        Cell: ({ row }) => (
          <>
            {row?.original?.assetSubTypeName
              ? row?.original?.assetSubTypeName
              : NO_VALUE}
          </>
        ),
      },
      {
        accessorKey: "vulnerabilityName",
        header: "Vulnerability Name",
      },
      {
        accessorKey: "easeOfExploitationName",
        header: "Ease of Exploitation",
        Cell: ({ row }) => (
          <>
            {row?.original?.easeOfExploitationName
              ? row?.original?.easeOfExploitationName
              : NO_VALUE}
          </>
        ),
      },
      {
        accessorKey: "riskRatingName",
        header: "Risk Rating",
        Cell: ({ row }) => {
          return row?.original?.riskRatingName ? (
            <Chip
              size="small"
              label={row?.original?.riskRatingName}
              variant="outlined"
              sx={{
                color:
                  getChipDesign(row?.original?.riskRatingName, themeMode)?.color ||
                  "black",
                border:
                  "1.5px solid " +
                  getChipDesign(row?.original?.riskRatingName, themeMode)?.color,
              }}
            />
          ) : (
            ""
          );
        },
      },
      {
        accessorKey: "assetURL",
        header: "IP Address/URL",
        Cell: ({ row }) => (
          <>{row?.original?.assetURL ? row?.original?.assetURL : NO_VALUE}</>
        ),
      },
      {
        accessorKey: "noOfEvidences",
        header: "No Of Evidences",
      },
      {
        accessorKey: "clientStatusName",
        header: "Client Status",
      },
      {
        accessorKey: "internalStatusName",
        header: "Internal Status",
      },
      {
        accessorKey: "testerStatusName",
        header: "Tester Status",
      },
      {
        accessorKey: "stageName",
        header: "Stage",
      },
      {
        accessorKey: "createdByName",
        header: "Added By",
      },
      ...(props?.scanDetails?.scanTypeName === "Configuration Audit"
        ? [
          {
            accessorKey: "complianceStatus",
            header: "Compliance Status",
          },
        ]
        : []),
    ],
    []
  );

  const handleDownloadTemplate = (normalUpload) => {
    dispatch(downlaodNessustemplate(normalUpload));
  };

  useEffect(() => {
    if (Object.keys(downlaodNessusTemplateResponse)?.length > 0) {
      const { fileContent, fileName } = downlaodNessusTemplateResponse;
      downloadTemplateFile(fileContent, fileName);
      return () => {
        dispatch(downlaodNessusTemplateSuccess({}));
      };
    }
  }, [downlaodNessusTemplateResponse]);

  const handleReset = () => {
    setFilterApplied(false);
    const resetData = {
      page,
      size: rowsPerPage,
      scanId: params?.scanId,
      clientOrgId,
    };
    setSelectedRiskRating([]);
    setSelectedConsultantStatus([]);
    setSelectedSecurityManagerName([]);
    setSelectedComplianceStatus({});
    setSelectedClientStatus([]);
    setFilters({});
    setDateFilter({});
    dispatch(getScanVulnerabilities(resetData));
  };

  const handleApplyFilters = () => {
    setFilterApplied(true);
    let filterData = {
      page: page,
      scanId: params?.scanId,
      clientOrgId,
      size: rowsPerPage,
      ...(selectedConsultantStatus?.length > 0 && {
        consultantStatus: selectedConsultantStatus
          ?.map((mode) => mode.vulStatusId)
          .join(","),
      }),
      ...(selectedSecurityManagerName?.length > 0 && {
        internalStatus: selectedSecurityManagerName
          ?.map((risk) => risk.smId)
          .join(","),
      }),
      ...(selectedComplianceStatus?.complianceStatusCode && {
        complianceStatus: selectedComplianceStatus?.complianceStatusCode,
      }),
      ...(selectedTesterName?.length > 0 && {
        testerStatus: selectedTesterName
          ?.map((risk) => risk.testerId)
          .join(","),
      }),
      ...(selectedClientStatus?.length > 0 && {
        clientStatus: selectedClientStatus
          ?.map((mode) => mode.vulStatusId)
          .join(","),
      }),
      ...(selectedRiskRating?.length > 0 && {
        riskRating: selectedRiskRating
          ?.map((mode) => mode.riskRatingCode)
          .join(","),
      }),
      ...(filters?.assetType && { assetType: filters?.assetType }),
      ...(filters?.assetSubType && { assetSubType: filters?.assetSubType }),
      ...(filters?.assetURL && { assetUrl: filters?.assetURL }),
      ...(filters?.assetId && { assetId: filters?.assetId }),

      ...(dateFilter?.startDate && {
        createdOnStartDate: dateFilter?.startDate,
      }),
      ...(dateFilter?.endDate && {
        createdOnEndDate: dateFilter?.endDate,
      }),
    };
    dispatch(getScanVulnerabilities(filterData));
  };

  // useEffect(() => {
  //   dispatch(getScanVulnerabilities({ scanId: params?.scanId, clientOrgId }));
  // }, [uploadNessusReponse]);

  useEffect(() => {
    if (scanVulnerabilities?.data?.length) {
      dispatch(getScanDetails(params?.scanId, clientOrgId));
    }
  }, [scanVulnerabilities]);
  const handleChangeRowSelect = (selectedIds) => {
    setSelectedIds(selectedIds);
  };
  const handleDeleteVulnerabilityDialog = () => {
    setOpenDeleteVulnerabilityDialog(!openDeleteAssetDialog);
  };
  const handleVulnerabilityDeleteAction = () => {
    const payload = {
      deleteIdList: selectedIds,
    };
    setSelectedIds([]);
    dispatch(
      deleteVulnerabilityToScan(params?.scanId, clientOrgId, payload)
    ).then((response) => {
      if (response?.status >= 200 && response?.status < 400) {
        dispatch(
          getScanVulnerabilities({ scanId: params?.scanId, clientOrgId, page: page, size: rowsPerPage })
        );
        dispatch(getScanDetails(params?.scanId, clientOrgId));
      }
      handleDeleteVulnerabilityDialog();
    });
  };

  const uploadCsv = () => {
    const fileInput = document.getElementById("fileInput");
    const file = fileInput.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        dispatch(
          uploadNessusCsv(clientOrgId, params?.scanId, formData, normalUpload)
        ).then(() => {
          setShowError(true);
        });
      } catch (err) { }
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(1);
  };

  const handleAutoClose = () => {
    dispatch(
      smartCloseVulnerabilities({
        scanId: params?.scanId,
        clientOrgId: clientOrgId,
      })
    ).then(() => {
      // dispatch(getScanVulnerabilities(params?.scanId, clientOrgId));
    });
  };

  const handleSubmitStatus = () => {
    const vulnerabilityIdList = statusDetail?.data === "all" ? [] : selectedIds;
    const payload = {
      status: statusDetail?.status,
      vulnerabilityIdList,
      clientOrgId,
      scanId: params?.scanId,
      markFor: statusDetail?.markFor,
    };

    if (statusDetail?.data === "all" || selectedIds?.length > 0) {
      dispatch(updateVulnerabilityStatus(payload))
        .then((res) => {
          // if (res?.status >= 200 && res?.status < 400) {
          dispatch(
            getScanVulnerabilities({
              scanId: params?.scanId,
              clientOrgId,
              page: page,
              size: rowsPerPage,
            })
          ).then(() => {
            setStatusDetail({ status: "", markFor: "", data: "" });
            setVisibleActionModal(false);
          });
          setSelectedIds([]);
          // }
        })
        .catch((error) => {
          console.error("Error updating vulnerability status:", error);
        });
    }
  };

  useEffect(() => {
    if (!filterApplied) {
      dispatch(
        getScanVulnerabilities({
          scanId: params?.scanId,
          clientOrgId,
          page,
          size: rowsPerPage,
          ...(selectedSecurityManagerName?.length > 0 && {
            internalStatus: selectedSecurityManagerName
              ?.map((risk) => risk.smId)
              .join(","),
            ...(selectedTesterName?.length > 0 && {
              testerStatus: selectedTesterName
                ?.map((risk) => risk.testerId)
                .join(","),
            }),
          }),
          // internalStatus: selectedSecurityManagerName
        })
      );
    }
  }, [page, rowsPerPage, uploadNessusReponse]);

  useEffect(() => {
    if (filterApplied) {
      const payload = {
        size: rowsPerPage,
        page: page,
        ...(selectedConsultantStatus?.length > 0 && {
          consultantStatus: selectedConsultantStatus
            ?.map((status) => status.vulStatusId)
            .join(","),
        }),
        ...(selectedComplianceStatus?.complianceStatusCode && {
          complianceStatus: selectedComplianceStatus?.complianceStatusCode,
        }),
        ...(selectedClientStatus?.length > 0 && {
          clientStatus: selectedClientStatus
            ?.map((clientStatus) => clientStatus.vulStatusId)
            .join(","),
        }),
        ...(selectedRiskRating?.length > 0 && {
          riskRating: selectedRiskRating
            ?.map((risk) => risk.riskRatingCode)
            .join(","),
        }),
        ...(selectedSecurityManagerName?.length > 0 && {
          internalStatus: selectedSecurityManagerName
            ?.map((risk) => risk.smId)
            .join(","),
        }),
        ...(selectedTesterName?.length > 0 && {
          testerStatus: selectedTesterName
            ?.map((risk) => risk.testerId)
            .join(","),
        }),
        ...(filters?.assetType && { assetType: filters?.assetType }),
        ...(filters?.assetSubType && { assetSubType: filters?.assetSubType }),
        ...(filters?.assetURL && { assetURL: filters?.assetURL }),
        ...(filters?.assetId && { assetId: filters?.assetId }),

        ...(dateFilter?.startDate && {
          startDate: dateFilter?.startDate,
        }),
        ...(dateFilter?.endDate && {
          endDate: dateFilter?.endDate,
        }),
      };
      dispatch(getScanVulnerabilities(payload));
    }
  }, [page, rowsPerPage]);
  useEffect(() => {
    if (userRoles) {
      let mappedData = userRoles.map((item) => {
        if (item === "CO_SECURITY_MANAGER") {
          return {
            label: "Security Manager",
            value: item,
          };
        } else {
          return {
            label: "Tester",
            value: item,
          };
        }
      });
      setRoleList(mappedData);
    }
  }, [userRoles]);

  useEffect(() => {
    dispatch(getConsultantSMList());
    dispatch(getConsultantTesterList());
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={2}
          >
            {/* <Typography>View:</Typography>
            <FormControl>
              <RadioGroup
                row
                aria-label="gender"
                value={valueLabel}
                onChange={(e) => setValueLabel(e.target.value)}
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="all" control={<Radio />} label="All" />
                <FormControlLabel
                  value="unique"
                  control={<Radio />}
                  label="Unique"
                />
              </RadioGroup>
            </FormControl> */}
          </Stack>
        </Grid>
      </Grid>
      {/* <Typography variant="h2">Vulnerabilities</Typography> */}
      <VmTable
        tableTitle={""}
        enableRowSelection={true}
        enablePagination={false}
        enableBottomToolbar={false}
        headerAction={
          <ActionButton
            open={open}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            setOpen={setOpen}
            handleDeleteVulnerabilityDialog={handleDeleteVulnerabilityDialog}
            uploadCsv={uploadCsv}
            enableManagerFilter={true}
            // scanVulnerabilities={scanVulnerabilities?.data || []}
            scanDetails={props?.scanDetails}
            setOpenNessusUploadDialog={setOpenNessusUploadDialog}
            handleAutoClose={handleAutoClose}
            page={page}
            rowsPerPage={rowsPerPage}
            enableAssetFilter={true}
            selectedComplianceStatus={selectedComplianceStatus}
            setSelectedComplianceStatus={setSelectedComplianceStatus}
            // enableComplianceStatusFilter={true}
            enableComplianceStatusFilter={
              props?.scanDetails?.scanTypeName === "Configuration Audit"
            }
            enableRiskRatingFilter={true}
            enableApplyFilter={true}
            enableClearFilter={true}
            enableClientStatusFilter={true}
            enableConsultantStatusFilter={false}
            enableReportedOnFilter={true}
            assetsTypes={assetsTypes}
            assetSubTypes={assetSubTypes}
            assetSubTypeApi={getAssetsSubType}
            consultantStatusValue={consultantStatusValue}
            consultantStatusApi={getConsultantStatusValue}
            riskRatingValue={riskRatingValue}
            riskRatingApi={getRiskRatingValue}
            clientStatusValue={clientStatusValue}
            clientStatusApi={getClientStatusValue}
            assetTypeApi={getAssetsType}
            filters={filters}
            setFilters={setFilters}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            selectedConsultantStatus={selectedConsultantStatus}
            setSelectedConsultantStatus={setSelectedConsultantStatus}
            selectedClientStatus={selectedClientStatus}
            setSelectedClientStatus={setSelectedClientStatus}
            selectedRiskRating={selectedRiskRating}
            setSelectedRiskRating={setSelectedRiskRating}
            handleApplyFilters={handleApplyFilters}
            handleReset={handleReset}
            setNormalUpload={setNormalUpload}
            setVisibleActionModal={setVisibleActionModal}
            enableUrlIpAddressFilter={true}
            setSelectedSecurityManagerName={setSelectedSecurityManagerName}
            selectedSecurityManagerName={selectedSecurityManagerName}
            consultantSMList={consultantSMList}
            enableTesterFilter={true}
            consultantTesterList={consultantTesterList}
            setSelectedTesterName={setSelectedTesterName}
            selectedTesterName={selectedTesterName}
          // getAssetListApi={fetchAssetOfOrgForFilter}
          // assetList={OrgAssetsListForFilter}
          // enableAssetListFilter={true}
          />
        }
        columns={columns}
        data={scanVulnerabilities?.data || []}
        selectedIdsField={"scanVulnerabilityId"}
        selectedIds={selectedIds}
        handleChangeRowSelect={handleChangeRowSelect}
      />
      <TablePagination
        component="div"
        count={
          scanVulnerabilities?.totalRecords
            ? scanVulnerabilities?.totalRecords
            : 0
        }
        page={page - 1}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
      />
      <DialogBox
        maxWidth="sm"
        modelTitle={"Delete Vulnerability"}
        open={openDeleteAssetDialog}
        dialogContent={
          <Delete
            handleDeleteAction={handleVulnerabilityDeleteAction}
            handleClose={handleDeleteVulnerabilityDialog}
            loading={setDataloading}
          />
        }
      />
      {uploadNessusReponse?.errors?.length > 0 ? (
        <DialogBox
          handleClose={() => setShowError(false)}
          maxWidth="sm"
          modelTitle={"File Upload Status"}
          open={showError}
          dialogContent={<ErrorDisplay errorDetails={uploadNessusReponse} />}
        />
      ) : (
        ""
      )}

      <DialogBox
        maxWidth="lg"
        modelTitle="Upload File"
        open={openNessusUploadDialog}
        handleClose={() => setOpenNessusUploadDialog(false)}
        dialogContent={
          <NessusUpload
            scanId={params?.scanId}
            clientOrgId={clientOrgId}
            normalUpload={normalUpload}
            setOpenNessusUploadDialog={setOpenNessusUploadDialog}
            handleDownloadTemplate={handleDownloadTemplate}
          />
        }
      />
      <DialogBox
        maxWidth="md"
        modelTitle={"Update Status"}
        open={visibleActionModal}
        handleClose={() => setVisibleActionModal(false)}
        dialogContent={
          <ActionModal
            data={DataListType}
            roleList={roleList}
            handleClose={setVisibleActionModal}
            statusDetail={statusDetail}
            setStatusDetail={setStatusDetail}
            handleSubmitStatus={handleSubmitStatus}
            selectedIds={selectedIds}
            displayActionList={
              props?.scanDetails?.displayScanVulnerabilityAction
            }
          />
        }
      />
      <Box my={2}>
        <Divider />
      </Box>
      {/* <VmTable columns={visibilityColumns} data={visibilityData} /> */}
    </>
  );
};

export default ScanVulnerabilities;
