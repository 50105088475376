import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import BuildSharpIcon from "@mui/icons-material/BuildSharp";
import InsightsSharpIcon from "@mui/icons-material/InsightsSharp";
import QrCodeScannerSharpIcon from "@mui/icons-material/QrCodeScannerSharp";

import {
  Chip,
  Grid,
  IconButton,
  TablePagination,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { blue, green, grey, red } from "@mui/material/colors";
import { makeStyles, useTheme } from "@mui/styles";
import { IconFile, IconFileTypePdf } from "@tabler/icons-react";
import { ASSET_TYPE, NO_VALUE } from "constant";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { dispatch, useSelector } from "store";
import { getScanStatusFilterList } from "store/slices/clientScanReducer";
import { getRiskRatingValue } from "store/slices/clientVulnerabilityReducer";

import {
  departmentDownloadVulReport,
  fetchDepartmentInsightData,
  fetchDepartmentPublishedScansList,
  fetchDepartmentPublishedVulnerabilitiesList,
  fetchDepartmentRiskComplianceScore,
} from "store/slices/departmentReducer";
import {
  downloadScan,
  downloadScanSuccess,
  getScanSubType,
  getScanTypes,
} from "store/slices/scanReducer";
import {
  getClientStatusValue,
  getConsultantStatusValue,
} from "store/slices/vulnerabilityReducer";
import VmTable from "ui-component/table";
import { downloadTemplateFile, getChipDesign } from "utils/helpers";
import GenericFilterBar from "views/Common/genericFilterBar";
import GenericScanFilterBar from "views/Common/genericScanFilterBar";
import { pendingWithScanFilterCode } from "views/pages/client/scan/constant";
import CustomCircularProgressWithLabel from "views/pages/common/dashboard/graphs/progressBar";
import { SCAN_TYPE_DETAIL } from "views/pages/consultant/assets/constant";
import AssetDetailPiechart from "views/pages/consultant/assets/groupAssets/assetGroupGraphs/assetDetailPie";
import { rowsPerPageOptions } from "views/pages/consultant/scan/constant";

const DepartmentInsights = () => {
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  const param = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [assetDetailTab, setAssetDetailTab] = useState("insight");
  const [scanType, setScanType] = useState(ASSET_TYPE.ALL);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isDarkMode = theme.palette.mode === "dark";
  const [exportData, setExportData] = useState(false);
  const [vulnerabilityName, setVulnerabilityName] = useState("");
  const {
    departmentRiskComplianceScore,
    departmentPublishedVulnerabilitiesList,
    departmentPublishedScansList,
    departmentDownloadVulReportRes,
    departmentInsightData,
  } = useSelector((state) => state?.departmentState);
  const departmentId = param?.id;
  const clientOrgId = location?.state;

  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [filters, setFilters] = useState({
    assetType: "",
    assetSubType: "",
    assetId: "",
  });

  const [selectedConsultantStatus, setSelectedConsultantStatus] = useState([]);
  const [selectedClientStatus, setSelectedClientStatus] = useState([]);
  const [selectedRiskRating, setSelectedRiskRating] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const handleGoBack = () => {
    navigate(-1);
  };
  const { allScans, scanSubTypes, scanTypes, downloadScanData } = useSelector(
    (state) => state?.scansState
  );
  const [selectedScanMode, setSelectedScanMode] = useState({});
  const [scanFilter, setScanFilter] = useState({
    scanType: "",
    scanSubType: "",
    scanName: "",
    scanRefNo: "",
  });
  const [createdOnFilter, setCreatedOnFilter] = useState({
    startDate: moment().subtract(1, "year").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [publishedOnFilter, setPublishedOnFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedPendingOption, setSelectedPendingOption] = useState({
    client: null,
    consultant: null,
  });
  const [selectedScanStatus, setSelectedScanStatus] = useState([]);

  const { scanStatusFilterList } = useSelector(
    (state) => state?.clientScansState
  );

  const { clientStatusValue, consultantStatusValue } = useSelector(
    (state) => state?.vulnerabilityState
  );

  const { riskRatingValue } = useSelector(
    (state) => state?.clientVulnerabilityReducerState
  );

  const HandleEditAsset = () => {
    navigate(`/${location?.state}/assets/edit`, { state: param?.assetId });
  };

  const handleVulnerabilitiesExport = () => {
    const payload = {
      departmentId,
      clientOrgId,
    };
    dispatch(departmentDownloadVulReport(payload)).then(() => {
      setExportData(true);
    });
  };

  useEffect(() => {
    if (Object.keys(departmentDownloadVulReportRes)?.length > 0 && exportData) {
      const { fileContent, fileName } = departmentDownloadVulReportRes;
      downloadTemplateFile(fileContent, fileName);
    }
  }, [exportData]);

  useEffect(() => {
    const payload = {
      departmentId: departmentId,
      clientOrgId,
      page,
      size: rowsPerPage,
      //   scanType: scanType,
    };
    if (assetDetailTab === "vulnerabilities") {
      dispatch(fetchDepartmentPublishedVulnerabilitiesList(payload));
    } else if (assetDetailTab === "scans") {
      dispatch(fetchDepartmentPublishedScansList(payload));
    } else if (assetDetailTab === "insight") {
      dispatch(
        fetchDepartmentInsightData({
          departmentId,
          clientOrgId,
        })
      );
      dispatch(
        fetchDepartmentRiskComplianceScore({
          departmentId,
          clientOrgId,
        })
      );
    }
  }, [assetDetailTab, scanType, rowsPerPage, page]);

  const iconStyle = { paddingRight: "3px" };
  const vulColumns = [
    {
      accessorKey: "vulnerabilityName",
      header: "Vulnerability Name",
      size: 200,
      Cell: ({ row }) => (
        <>
          {row?.original?.vulnerabilityName ? (
            <span title={row.original.vulnerabilityName}>
              {row.original.vulnerabilityName}
            </span>
          ) : (
            "NA"
          )}
        </>
      ),
    },
    {
      accessorKey: "owaspCategoryDesc",
      header: "Owasp Category",
      size: 150,
    },
    {
      accessorKey: "riskRatingName",
      header: "Risk Rating",
      Cell: ({ row }) => {
        return row?.original?.riskRatingName ? (
          <Chip
            size="small"
            label={row?.original?.riskRatingName}
            variant="outlined"
            sx={{
              color:
                getChipDesign(row?.original?.riskRatingName, themeMode)
                  ?.color || "black",
              border:
                "1.5px solid " +
                getChipDesign(row?.original?.riskRatingName, themeMode)?.color,
            }}
          />
        ) : (
          ""
        );
      },
    },
    {
      accessorKey: "consultantStatusName",
      header: "Consultant Status",
      size: 250,
    },
    {
      accessorKey: "clientStatusName",
      header: "Client Status",
      size: 250,
    },
  ];
  const scanColumns = [
    {
      accessorKey: "organisationName",
      header: "Client Organization",
      size: 150,
    },
    {
      accessorKey: "scanName",
      header: "Scan Name",
      size: 100,
      Cell: ({ row }) => (
        <>
          {row?.original?.scanName ? (
            <span title={row.original.scanName.trim()}>
              {row.original.scanName.trim()?.length >= 10
                ? `${row.original.scanName.trim().substring(0, 15)}...`
                : row.original.scanName}
            </span>
          ) : (
            NO_VALUE
          )}
        </>
      ),
    },
    {
      accessorKey: "scanReferenceNo",
      header: "Scan Ref Code",
      size: 150,
      muiTableBodyCellProps: ({ row }) => ({
        onClick: (event) => {
          sessionStorage.setItem("clientOrgId", row?.original?.clientOrgId);
          if (localStorage.getItem("organizationType") === "CO") {
            navigate(`/scan/${row?.original?.scanDetailsId}`, {
              state: row.original.clientOrgId,
            });
          } else {
            navigate(`/scan-detail/${row?.original?.scanDetailsId}`, {
              state: row.original.clientOrgId,
            });
          }
        },
        sx: {
          cursor: "pointer",
          // color: theme.palette.secondary.main,
          textDecoration: "underline",
        },
      }),
    },
    {
      accessorKey: "totalAsset",
      header: "Total Assets",
      size: 100,
      Cell: ({ row }) => (
        <Grid
          display={"flex"}
          alignItems={"center"}
          textAlign={"center"}
          justifyContent={"center"}
        >
          <span>{row?.original?.totalAsset}</span>
        </Grid>
      ),
    },
    {
      accessorKey: "scanModeDesc",
      header: "Scan Detail",
      size: 300,
      Cell: ({ row }) => (
        <Grid gap={0.2} display={"flex"} flexWrap={"wrap"}>
          {row?.original?.scanModeDesc ? (
            <Chip
              label={row?.original?.scanModeDesc}
              // color="primary"
              variant="outlined"
              size="small"
            />
          ) : (
            ""
          )}
          {row?.original?.scanCategoryDesc ? (
            <Chip
              label={row?.original?.scanCategoryDesc}
              // color="error"
              variant="outlined"
              size="small"
            />
          ) : (
            ""
          )}
          {row?.original?.status ? (
            <Chip
              label={row?.original?.status}
              variant="outlined"
              size="small"
              // sx={{ color: "#edd83b", border: " 1px solid #edd83b" }}
            />
          ) : (
            ""
          )}
          {row?.original?.scanTypeName ? (
            <Chip
              label={row?.original?.scanTypeName}
              // color="success"
              variant="outlined"
              size="small"
            />
          ) : (
            ""
          )}
          {row?.original?.scanSubTypeName ? (
            <Chip
              label={row?.original?.scanSubTypeName}
              // color="info"
              variant="outlined"
              size="small"
            />
          ) : (
            ""
          )}
        </Grid>
      ),
    },
    {
      accessorKey: "createdOn",
      header: "Created On",
      size: 100,
      Cell: ({ cell, row }) => (
        <>
          {row?.original?.createdOn &&
            moment(row.original.createdOn).format("Do MMM YYYY")}
        </>
      ),
    },
    {
      accessorKey: "actions",
      header: "Actions",
      size: 50,
      Cell: ({ row }) => (
        <>
          {row?.original?.downloadExcel && (
            <IconButton title="Excel">
              <IconFile
                onClick={() => {
                  handleScanDownload({
                    scanId: row?.original?.scanDetailsId,
                    clientOrgId: row?.original?.clientOrgId,
                    fileType: "EXCEL",
                  });
                }}
                sx={{ cursor: "pointer" }}
              />
            </IconButton>
          )}
          {row?.original?.downloadPDF && (
            <IconButton title="PDF">
              <IconFileTypePdf
                onClick={() => {
                  handleScanDownload({
                    scanId: row?.original?.scanDetailsId,
                    clientOrgId: row?.original?.clientOrgId,
                    fileType: "PDF",
                  });
                }}
                sx={{ cursor: "pointer" }}
              />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  // function calculateValues(category, color) {
  //   if (category) {
  //     const categoryData = departmentInsightData?.find((item) =>
  //       item.hasOwnProperty(category)
  //     );

  //     if (!categoryData) {
  //       throw new Error(`Category "${category}" not found in data`);
  //     }

  //     const values = categoryData[category];

  //     const result = [
  //       {
  //         name: "Open",
  //         value:
  //           category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
  //             ? values?.openComplianceCount
  //             : values.openCritical +
  //               values.openHigh +
  //               values.openMedium +
  //               values.openLow +
  //               values.openInfo,
  //         color: color[900],
  //       },
  //       {
  //         name: "Close",
  //         value:
  //           category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
  //             ? values?.closeComplianceCount
  //             : values.closeCritical +
  //               values.closeHigh +
  //               values.closeMedium +
  //               values.closeLow +
  //               values.closeInfo,
  //         color: color[200],
  //       },
  //       {
  //         name: "Won't Fix",
  //         value:
  //           category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
  //             ? values?.wontFixedComplianceCount
  //             : values.wontFixCritical +
  //               values.wontFixHigh +
  //               values.wontFixMedium +
  //               values.wontFixLow +
  //               values.wontFixInfo,
  //         color: color[300],
  //       },
  //       {
  //         name: "Accepted Risk",
  //         value:
  //           category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
  //             ? values?.acceptedRiskComplianceCount
  //             : values.acceptedRiskCritical +
  //               values.acceptedRiskHigh +
  //               values.acceptedRiskMedium +
  //               values.acceptedRiskLow +
  //               values.acceptedRiskInfo,
  //         color: color[700],
  //       },
  //       {
  //         name: "False Positive",
  //         value:
  //           category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
  //             ? values?.falsePositiveComplianceCount
  //             : values.falsePositiveCritical +
  //               values.falsePositiveHigh +
  //               values.falsePositiveMedium +
  //               values.falsePositiveLow +
  //               values.falsePositiveInfo,
  //         color: color[500],
  //       },
  //       {
  //         name: "Fixed",
  //         value:
  //           category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
  //             ? values?.fixedComplianceCount
  //             : values.fixedCritical +
  //               values.fixedHigh +
  //               values.fixedMedium +
  //               values.fixedLow +
  //               values.fixedInfo,
  //         color: color[400],
  //       },
  //       // {
  //       //   name: "Total",
  //       //   value:
  //       //     values.totalOpen +
  //       //     values.totalClose +
  //       //     // values.totalFix +
  //       //     // values.totalWontFix +
  //       //     // values.totalAcceptedRisk +
  //       //     values.totalFalsePositive,
  //       //   color: color[800],
  //       // },
  //     ];

  //     return {
  //       result,
  //       total:
  //         category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
  //           ? values?.closeComplianceCount +
  //             values?.falsePositiveComplianceCount +
  //             values?.openComplianceCount
  //           : values.totalOpen + values.totalClose + values.totalFalsePositive,
  //     };
  //   } else {
  //     return {
  //       result: {},
  //       total: 0,
  //     };
  //   }
  // }

  function calculateValues(category) {
    const colors = [
      "#75F993", // Open
      "#7086FD", // Close
      "#FFAE4C", // Won't Fix
      "#07DBFA", // Accepted Risk
      "#ff9595", // Won't Fix
      "#988AFC", // False Positive
      "#DB4635", // Fixed
    ];

    if (category) {
      const categoryData = departmentInsightData?.find((item) =>
        item.hasOwnProperty(category)
      );

      if (!categoryData) {
        throw new Error(`Category "${category}" not found in data`);
      }

      const values = categoryData[category];

      const result = [
        {
          name: "Pending",
          value:
            category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
              ? values?.openComplianceCount
                ? values?.openComplianceCount - values?.fixedComplianceCount
                : values?.openComplianceCount
              : values.totalOpen
              ? values.totalOpen - values.totalFix
              : values.totalOpen,
          color: colors[0], // First color in the array
        },
        {
          name: "Close",
          value:
            category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
              ? values?.closeComplianceCount
              : values.totalClose,
          color: colors[1], // Second color
        },
        {
          name: "Won't Fix",
          value:
            category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
              ? values?.wontFixedComplianceCount
              : values.totalWontFix,
          color: colors[2], // Third color
        },
        {
          name: "Accepted Risk",
          value:
            category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
              ? values?.acceptedRiskComplianceCount
              : values.totalAcceptedRisk,
          color: colors[3], // Fourth color
        },
        {
          name: "False Positive",
          value:
            category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
              ? values?.falsePositiveComplianceCount
              : values.totalFalsePositive,
          color: colors[4], // Fifth color
        },
        {
          name: "Fixed",
          value:
            category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
              ? values?.fixedComplianceCount
              : values.totalFix,
          color: colors[5], // Sixth color
        },
      ];
      return {
        result,
        total:
          category === SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT
            ? values?.openComplianceCount +
              values?.falsePositiveComplianceCount +
              values?.closeComplianceCount
            : values.totalOpen +
              values?.totalFalsePositive +
              values?.totalClose,
      };
    } else {
      return {
        result: {},
        total: 0,
      };
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(1);
  };

  const handleReset = () => {
    setFilterApplied(false);
    const resetData = {
      page,
      departmentId,
      size: rowsPerPage,
      createdOnStartDate: createdOnFilter?.startDate,
      createdOnEndDate: createdOnFilter?.endDate,
      clientOrgId,
    };
    setSelectedPendingOption({});
    setSelectedScanMode({});
    setSelectedScanStatus([]);
    setScanFilter({});
    setCreatedOnFilter(createdOnFilter);
    setPublishedOnFilter({});
    setVulnerabilityName({});
    setSelectedRiskRating([]);
    setSelectedConsultantStatus([]);
    setSelectedClientStatus([]);
    setVulnerabilityName("");
    setFilters({});
    setDateFilter({});
    dispatch(
      assetDetailTab === "scans"
        ? fetchDepartmentPublishedScansList(resetData)
        : fetchDepartmentPublishedVulnerabilitiesList(resetData)
    );
  };
  const handleApplyFilters = () => {
    setFilterApplied(true);
    let filterData = {
      page: page,
      size: rowsPerPage,
      departmentId,
      clientOrgId: location?.state,

      scanTypeCode: scanTypes.find(
        (scanType) => scanType.scanTypeId === scanFilter?.scanType
      )?.scanTypeCode,
      vulnerabilityName,
      ...(selectedScanStatus?.length > 0 && {
        scanStatus: selectedScanStatus?.map((mode) => mode.id).join(","),
      }),
      ...(selectedConsultantStatus?.length > 0 && {
        consultantStatus: selectedConsultantStatus
          ?.map((mode) => mode.vulStatusId)
          .join(","),
      }),
      ...(selectedClientStatus?.length > 0 && {
        clientStatus: selectedClientStatus
          ?.map((mode) => mode.vulStatusId)
          .join(","),
      }),
      ...(selectedRiskRating?.length > 0 && {
        riskRating: selectedRiskRating
          ?.map((mode) => mode.riskRatingCode)
          .join(","),
      }),
      // ...(selectedScanMode?.length > 0 && {
      //   scanMode: selectedScanMode?.map((mode) => mode.scanModeCode).join(","),
      // }),
      ...(selectedScanMode && { scanMode: selectedScanMode?.scanModeCode }),
      ...(scanFilter?.scanType && { scanType: scanFilter.scanType }),
      ...(scanFilter?.scanSubType && { scanSubType: scanFilter.scanSubType }),
      ...(scanFilter?.scanName && { scanName: scanFilter.scanName }),
      ...(scanFilter?.scanRefNo && { scanRefNo: scanFilter.scanRefNo }),
      ...(createdOnFilter?.startDate && {
        createdOnStartDate: createdOnFilter?.startDate,
      }),
      ...(createdOnFilter?.endDate && {
        createdOnEndDate: createdOnFilter?.endDate,
      }),
      ...(publishedOnFilter?.startDate && {
        publishedOnStartDate: publishedOnFilter?.startDate,
      }),
      ...(publishedOnFilter?.endDate && {
        publishedOnEndDate: publishedOnFilter?.endDate,
      }),
    };
    if (selectedPendingOption?.client) {
      filterData.pendingWith = pendingWithScanFilterCode?.CLIENT;
    }

    if (selectedPendingOption?.consultant) {
      filterData.pendingWith = pendingWithScanFilterCode?.CONSULTANT;
    }
    dispatch(
      assetDetailTab === "scans"
        ? fetchDepartmentPublishedScansList(filterData)
        : fetchDepartmentPublishedVulnerabilitiesList(filterData)
    );
  };

  useEffect(() => {
    if (filterApplied) {
      const payload = {
        page: page,
        size: rowsPerPage,
        departmentId,
        clientOrgId: location?.state,

        scanTypeCode: scanTypes.find(
          (scanType) => scanType.scanTypeId === scanFilter?.scanType
        )?.scanTypeCode,
        vulnerabilityName,
        ...(selectedScanStatus?.length > 0 && {
          scanStatus: selectedScanStatus?.map((mode) => mode.id).join(","),
        }),
        ...(selectedConsultantStatus?.length > 0 && {
          consultantStatus: selectedConsultantStatus
            ?.map((mode) => mode.vulStatusId)
            .join(","),
        }),
        ...(selectedClientStatus?.length > 0 && {
          clientStatus: selectedClientStatus
            ?.map((mode) => mode.vulStatusId)
            .join(","),
        }),
        ...(selectedRiskRating?.length > 0 && {
          riskRating: selectedRiskRating
            ?.map((mode) => mode.riskRatingCode)
            .join(","),
        }),
        // ...(selectedScanMode?.length > 0 && {
        //   scanMode: selectedScanMode
        //     ?.map((mode) => mode.scanModeCode)
        //     .join(","),
        // }),
        ...(selectedScanMode && { scanMode: selectedScanMode?.scanModeCode }),
        ...(scanFilter?.scanType && { scanType: scanFilter.scanType }),
        ...(scanFilter?.scanSubType && { scanSubType: scanFilter.scanSubType }),
        ...(scanFilter?.scanName && { scanName: scanFilter.scanName }),
        ...(scanFilter?.scanRefNo && { scanRefNo: scanFilter.scanRefNo }),
        ...(createdOnFilter?.startDate && {
          createdOnStartDate: createdOnFilter?.startDate,
        }),
        ...(createdOnFilter?.endDate && {
          createdOnEndDate: createdOnFilter?.endDate,
        }),
        ...(publishedOnFilter?.startDate && {
          publishedOnStartDate: publishedOnFilter?.startDate,
        }),
        ...(publishedOnFilter?.endDate && {
          publishedOnEndDate: publishedOnFilter?.endDate,
        }),
      };
      dispatch(
        assetDetailTab === "scans"
          ? fetchDepartmentPublishedScansList(payload)
          : fetchDepartmentPublishedVulnerabilitiesList(payload)
      );
    } else {
      const payload = {
        page: page,
        size: rowsPerPage,
        departmentId,
        clientOrgId: location?.state,
      };
      dispatch(
        assetDetailTab === "scans"
          ? fetchDepartmentPublishedScansList(payload)
          : fetchDepartmentPublishedVulnerabilitiesList(payload)
      );
    }
  }, [page, rowsPerPage]);

  const handleScanDownload = (payload) => {
    // if (userActionAccess(ACTION_TYPE?.GET_SCAN_REPORT)) {
    dispatch(downloadScan(payload));
    // }
  };

  useEffect(() => {
    if (Object.keys(downloadScanData)?.length > 0) {
      const { fileContent, fileName } = downloadScanData;
      downloadTemplateFile(fileContent, fileName);
      return () => {
        dispatch(downloadScanSuccess({}));
      };
    }
  }, [downloadScanData]);
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      // flexDirection:'row',
      padding: theme.spacing(1),
      // gap: theme.spacing(0.1),
    },
    filterContainer: {
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        maxWidth: "25%",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    tableContainer: {
      [theme.breakpoints.up("md")]: {
        maxWidth: "75%",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      {/* <GroupAssetHeader
                title={"Asset Name: " + assetDetail?.assetName}
                handleGoBack={handleGoBack}
                selectAsset={false}
                HandleEditAsset={HandleEditAsset}
            > */}

      <Grid pl={3}>
        <Grid
          justifyContent="center"
          xs={11}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Chip
            // size="small"
            sx={{
              mr: 2,
              //   mt: 1.5,
              cursor: "pointer",
            }}
            title="Back"
            icon={
              <ArrowBackIosNewIcon
                onClick={handleGoBack}
                fontSize="small"
                sx={{
                  color: "#000000",
                }}
              />
            }
          />
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={assetDetailTab}
            aria-label="Platform"
            onChange={(e) => setAssetDetailTab(e.target.value)}
            fullWidth
            size="small"
            sx={{
              // padding: "20px 40px 0px 40px",
              width: "100%", // Make ToggleButtonGroup take full width
            }}
          >
            {/* <ToggleButton value="assetOverview">
                            <InfoSharpIcon fontSize="small" style={iconStyle} /> Asset
                            Detail
                        </ToggleButton> */}

            <ToggleButton value="insight">
              <InsightsSharpIcon fontSize="small" style={iconStyle} />
              Insights
            </ToggleButton>

            <ToggleButton value="scans">
              <QrCodeScannerSharpIcon fontSize="small" style={iconStyle} />
              Scans
            </ToggleButton>
            <ToggleButton value="vulnerabilities">
              <BuildSharpIcon fontSize="small" style={iconStyle} />{" "}
              Vulneralibities
            </ToggleButton>
          </ToggleButtonGroup>
          {/* <Grid item> */}
          {/* </Grid> */}
        </Grid>

        <Grid
          sx={{
            bgcolor: "white",
            borderRadius: "8px",
            backgroundColor: isDarkMode
              ? theme.palette.background.default
              : theme.palette.background.paper,
            color: isDarkMode
              ? theme.palette.text.primary
              : theme.palette.text.secondary,
          }}
          // m={5}
          mt={1}
          xs={12}
        >
          {assetDetailTab === "assetOverview" ? (
            <></>
          ) : assetDetailTab === "insight" ? (
            <>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                pt={1}
                px={1}
              >
                <Grid item xs={4.8}>
                  {departmentInsightData?.length > 0 ? (
                    <AssetDetailPiechart
                      label={`${SCAN_TYPE_DETAIL.SECURE_CODE_REVIEW} (${
                        calculateValues(SCAN_TYPE_DETAIL.SECURE_CODE_REVIEW)
                          ?.total
                      })`}
                      data={
                        calculateValues(SCAN_TYPE_DETAIL.SECURE_CODE_REVIEW)
                          ?.result
                      }
                      height={350}
                      color={green[50]}
                      lineColor={green[400]}
                      legend={"exposure"}
                      dataKey="value"
                      axisLabel="name"
                      displayLegend={true}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={4.8}>
                  {departmentInsightData?.length > 0 ? (
                    <AssetDetailPiechart
                      label={`${SCAN_TYPE_DETAIL.VAPT} (${
                        calculateValues(SCAN_TYPE_DETAIL.VAPT)?.total
                      })`}
                      data={calculateValues(SCAN_TYPE_DETAIL.VAPT)?.result}
                      height={350}
                      color={green[50]}
                      lineColor={green[400]}
                      legend={"exposure"}
                      dataKey="value"
                      axisLabel="name"
                      displayLegend={true}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                {/* <Grid item xs={3.2}>
                                    {departmentInsightData?.length > 0 ? (
                                        <AssetDetailPiechart
                                            label={SCAN_TYPE_DETAIL.SECURE_CODE_REVIEW}
                                            data={calculateValues(SCAN_TYPE_DETAIL.SECURE_CODE_REVIEW, orange)}
                                            height={350}
                                            color={green[50]}
                                            lineColor={green[400]}
                                            legend={"exposure"}
                                            dataKey="value"
                                            axisLabel="name"
                                            displayLegend={true}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </Grid> */}
                <Grid
                  xs={2}
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    "@media (max-width: 768px)": {
                      flexDirection: "row",
                      flexWrap: "wrap",
                    },
                  }}
                >
                  {Object.keys(departmentRiskComplianceScore)?.length > 0 ? (
                    <>
                      <CustomCircularProgressWithLabel
                        value={departmentRiskComplianceScore?.risk_score}
                        label="Risk Score"
                        primaryColor={red[500]}
                        secondaryColor={grey[300]}
                        size={135}
                      />
                      <CustomCircularProgressWithLabel
                        value={departmentRiskComplianceScore?.compliance_score}
                        label="Compliance Score"
                        primaryColor={blue[500]}
                        secondaryColor={grey[300]}
                        size={135}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
                pt={1}
              >
                <Grid item xs={5.9}>
                  {departmentInsightData?.length > 0 ? (
                    <AssetDetailPiechart
                      label={`${SCAN_TYPE_DETAIL.GREYBOX} (${
                        calculateValues(SCAN_TYPE_DETAIL.GREYBOX)?.total
                      })`}
                      data={calculateValues(SCAN_TYPE_DETAIL.GREYBOX)?.result}
                      height={350}
                      color={green[50]}
                      lineColor={green[400]}
                      legend={"exposure"}
                      dataKey="value"
                      axisLabel="name"
                      displayLegend={true}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={5.9}>
                  {departmentInsightData?.length > 0 ? (
                    <AssetDetailPiechart
                      label={`${SCAN_TYPE_DETAIL.BLACKBOX} (${
                        calculateValues(SCAN_TYPE_DETAIL.BLACKBOX)?.total
                      })`}
                      data={calculateValues(SCAN_TYPE_DETAIL.BLACKBOX)?.result}
                      height={350}
                      color={green[50]}
                      lineColor={green[400]}
                      legend={"exposure"}
                      dataKey="value"
                      axisLabel="name"
                      displayLegend={true}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                {/* <Grid item xs={3.9}>
                                    {departmentInsightData?.length > 0 ? (
                                        <AssetDetailPiechart
                                            label={SCAN_TYPE_DETAIL.VULNERABILITY_ASSESSMENT}
                                            data={calculateValues(SCAN_TYPE_DETAIL.VULNERABILITY_ASSESSMENT, red)}
                                            height={350}
                                            color={green[50]}
                                            lineColor={green[400]}
                                            legend={"exposure"}
                                            dataKey="value"
                                            axisLabel="name"
                                            displayLegend={true}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </Grid> */}
              </Grid>

              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
                pt={1}
              >
                <Grid item xs={5.9}>
                  {departmentInsightData?.length > 0 ? (
                    <AssetDetailPiechart
                      label={`${SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT} (${
                        calculateValues(SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT)
                          ?.total
                      })`}
                      data={
                        calculateValues(SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT)
                          ?.result
                      }
                      height={350}
                      color={green[50]}
                      lineColor={green[400]}
                      legend={"exposure"}
                      dataKey="value"
                      axisLabel="name"
                      displayLegend={true}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={5.9}>
                  {departmentInsightData?.length > 0 ? (
                    <AssetDetailPiechart
                      label={`${SCAN_TYPE_DETAIL.VULNERABILITY_ASSESSMENT} (${
                        calculateValues(
                          SCAN_TYPE_DETAIL.VULNERABILITY_ASSESSMENT
                        )?.total
                      })`}
                      data={
                        calculateValues(
                          SCAN_TYPE_DETAIL.VULNERABILITY_ASSESSMENT
                        )?.result
                      }
                      height={350}
                      color={green[50]}
                      lineColor={green[400]}
                      legend={"exposure"}
                      dataKey="value"
                      axisLabel="name"
                      displayLegend={true}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              {assetDetailTab === "vulnerabilities" ? (
                <Grid textAlign={"end"} pt={2} pb={0} mt={0}>
                  <IconButton
                    color="inherit"
                    title="Export"
                    size="large"
                    disableRipple
                    aria-label="live customize"
                    onClick={handleVulnerabilitiesExport}
                  >
                    <IconFile />
                  </IconButton>
                </Grid>
              ) : (
                ""
              )}
              <Grid container className={classes.container}>
                {/* <Grid item className={classes.filterContainer} xs={3}>
                                    <ToggleButtonGroup
                                        color="primary"
                                        exclusive
                                        value={scanType}
                                        aria-label="Platform"
                                        onChange={(e, newValue) => setScanType(newValue)}
                                        fullWidth
                                        size="small"
                                        orientation="vertical"
                                    >
                                        <ToggleButton value={ASSET_TYPE.ALL}>All</ToggleButton>
                                        <ToggleButton value={SCAN_TYPE_DETAIL.BLACKBOX}>Black Box</ToggleButton>
                                        <ToggleButton value={SCAN_TYPE_DETAIL.GREYBOX}>Grey Box</ToggleButton>
                                        <ToggleButton value={SCAN_TYPE_DETAIL.SECURE_CODE_REVIEW}>SCR</ToggleButton>
                                        <ToggleButton value={SCAN_TYPE_DETAIL.VAPT}>VAPT</ToggleButton>
                                        <ToggleButton value={SCAN_TYPE_DETAIL.VULNERABILITY_ASSESSMENT}>VA</ToggleButton>
                                        <ToggleButton value={SCAN_TYPE_DETAIL.CONFIGURATION_AUDIT}>CA</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid> */}
                <Grid item xs={12}>
                  <VmTable
                    columns={
                      assetDetailTab === "scans" ? scanColumns : vulColumns
                    }
                    data={
                      assetDetailTab === "scans"
                        ? departmentPublishedScansList?.data?.length > 0
                          ? departmentPublishedScansList?.data
                          : []
                        : departmentPublishedVulnerabilitiesList?.data?.length >
                          0
                        ? departmentPublishedVulnerabilitiesList?.data
                        : []
                    }
                    enableBottomToolbar={false}
                    enablePagination={false}
                    headerAction={
                      assetDetailTab === "scans" ? (
                        <GenericScanFilterBar
                          page={page}
                          rowsPerPage={rowsPerPage}
                          enableScanFilter={true}
                          scanTypeApi={getScanTypes}
                          scanTypes={scanTypes}
                          scanSubTypeApi={getScanSubType}
                          scanSubTypes={scanSubTypes}
                          enableScanModeFilter={true}
                          enableScanSubTypeFilter={false}
                          enableScanRefFilter={false}
                          enableApplyFilter={true}
                          selectedScanMode={selectedScanMode}
                          setSelectedScanMode={setSelectedScanMode}
                          scanFilter={scanFilter}
                          setScanFilter={setScanFilter}
                          selectedScanStatus={selectedScanStatus}
                          setSelectedScanStatus={setSelectedScanStatus}
                          handleApplyFilters={handleApplyFilters}
                          enableClientStatus={false}
                          scanStatusList={scanStatusFilterList}
                          scanStatusApi={getScanStatusFilterList}
                          createdOnFilter={createdOnFilter}
                          setCreatedOnFilter={setCreatedOnFilter}
                          publishedOnFilter={publishedOnFilter}
                          setPublishedOnFilter={setPublishedOnFilter}
                          enableDateRangeFilter={true}
                          selectedPendingOption={selectedPendingOption}
                          setSelectedPendingOption={setSelectedPendingOption}
                          enablePendingWithFilter={false}
                          handleReset={handleReset}
                          enableClearFilter={true}
                        />
                      ) : (
                        <GenericFilterBar
                          page={page}
                          rowsPerPage={rowsPerPage}
                          enableRiskRatingFilter={true}
                          enableApplyFilter={true}
                          enableClearFilter={true}
                          enableClientStatusFilter={true}
                          enableConsultantStatusFilter={true}
                          enableReportedOnFilter={true}
                          consultantStatusValue={consultantStatusValue}
                          consultantStatusApi={getConsultantStatusValue}
                          riskRatingValue={riskRatingValue}
                          riskRatingApi={getRiskRatingValue}
                          clientStatusValue={clientStatusValue}
                          clientStatusApi={getClientStatusValue}
                          dateFilter={dateFilter}
                          setDateFilter={setDateFilter}
                          selectedConsultantStatus={selectedConsultantStatus}
                          setSelectedConsultantStatus={
                            setSelectedConsultantStatus
                          }
                          selectedClientStatus={selectedClientStatus}
                          setSelectedClientStatus={setSelectedClientStatus}
                          selectedRiskRating={selectedRiskRating}
                          setSelectedRiskRating={setSelectedRiskRating}
                          handleApplyFilters={handleApplyFilters}
                          handleReset={handleReset}
                          vulnerabilityName={vulnerabilityName}
                          setVulnerabilityName={setVulnerabilityName}
                          enableVulnerabilityNameFilter={true}
                          setFilters={setFilters}
                        />
                      )
                    }
                  />
                  <TablePagination
                    sx={{ display: "flex", justifyContent: "end" }}
                    count={
                      assetDetailTab === "scans"
                        ? departmentPublishedScansList?.totalRecords
                          ? departmentPublishedScansList?.totalRecords
                          : 0
                        : departmentPublishedVulnerabilitiesList?.totalRecords
                        ? departmentPublishedVulnerabilitiesList?.totalRecords
                        : 0
                    }
                    page={page - 1}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {/* </GroupAssetHeader> */}
    </>
  );
};

export default DepartmentInsights;
