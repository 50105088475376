import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  Chip,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import MainCard from "ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";

import PropTypes from "prop-types";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// material-ui
import { Box, Tab, Tabs } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports

import { gridSpacing } from "store/constant";

//
import { SCAN_STATUS_CODE } from "constant";
import { useSelector } from "react-redux";
import { dispatch } from "store";
import {
  fetchScanAssetVerification,
  getScanDetails,
  getScanStatusFlow,
  handleUpdateScanStatus,
  manageSelectedScanDetailsTab,
  publishScan,
  submitToSM,
  submitToTester,
} from "store/slices/scanReducer";
import ConfirmChangeScanStatus from "ui-component/scanStatusChange";
import TimeLine from "ui-component/timeline";
import Comments from "./ScanComments";
import ReportConfiguration from "./reportConfiguration";
import ReportHistory from "./reportHistory";
import ScanAssets from "./scanAssets";
import ScanDetails from "./scanDetails";
import ScanVulnerabilities from "./scanVulnerabilities";
import TestCase from "./testCases/testCasesList";
import DialogBox from "ui-component/dialogbox";
import AssetsLastScanDetail from "./assetLastScanDetail";

// tabs panel
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ScanDetail = () => {
  const theme = useTheme();
  const location = useLocation();
  const locationPath = location?.pathname?.split("/")[1];
  const [locationState, setLocationState] = useState();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const params = useParams();
  const [tabsOption, setTabsOption] = useState([]);
  const [openScanStatusConfirmDialog, setOpenScanStatusConfirmDialog] =
    useState(false);

  const [description, setDescription] = useState("");
  const { scanDetails } = useSelector((state) => state?.scansState);
  const { selectedScanDetailsTab, scanStatusFlow } = useSelector(
    (state) => state?.scansState
  );
  const [assetsCount, setAssetsCount] = useState(0);
  const [showError, setShowError] = useState(false);
  const [selectedScanStatus, setSelectedScanStatus] = useState();
  const { scanVulnerabilities } = useSelector(
    (state) => state?.vulnerabilityState
  );
  const { assetsForScan, publishError } = useSelector(
    (state) => state?.scansState
  );
  const { scanAssetVerificationList } = useSelector(
    (state) => state?.scansState
  );
  const [openScanAssetVerificationDialog, setOpenScanAssetVerificationDialog] =
    useState(false);
  const clientOrgId = sessionStorage.getItem("clientOrgId");
  const displayScanStatus = scanDetails?.displayScanStatus;
  const updateReportConfig = scanDetails?.updateReportConfiguration;
  const showScanActionButton =
    displayScanStatus?.publishScan ||
    displayScanStatus?.markAsChangeRequested ||
    displayScanStatus?.markAsReviewRequested ||
    displayScanStatus?.markAsAbort ||
    displayScanStatus?.markAsHold ||
    displayScanStatus?.markAsScheduled ||
    displayScanStatus?.markAsWIP;
  useEffect(() => {
    // dispatch(getScanStageFlow(params?.scanId, clientOrgId));
    dispatch(getScanStatusFlow(params?.scanId, clientOrgId));
    // dispatch(getScanVulnerabilities({ scanId: params?.scanId, clientOrgId }));
    // if (userActionAccess(ACTION_TYPE?.GET_SCAN_DTL)) {
    dispatch(getScanDetails(params?.scanId, clientOrgId));
    // }
    // dispatch(getAssetsForScan(params?.scanId));
  }, [params?.scanId]);
  useEffect(() => {
    if (!locationState) {
      setLocationState(location.state);
    }
  }, [location]);
  useEffect(() => {
    // tabs option
    setTabsOption([
      {
        label: "Details",
      },
      {
        label: `Assets (${scanDetails?.totalAsset || 0})`,
      },
      {
        label: `Vulnerabilities (${scanDetails?.totalVulnerabilities || 0})`,
      },
      ...(scanDetails?.testPlanTabBoolean && false
        ? [
            {
              label: "Test Case",
            },
          ]
        : []),
      {
        label: "Report Configuration",
      },
      {
        label: "Reports",
      },
      {
        label: "Status Flow",
      },
      {
        label: "Comments",
      },
    ]);
  }, [assetsForScan, scanVulnerabilities, scanDetails]);
  const { type } = useParams();
  const handleChange = (event, newValue) => {
    dispatch(manageSelectedScanDetailsTab(newValue));
  };
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSubmitToTester = () => {
    dispatch(submitToTester(params?.scanId, clientOrgId))
      .then(() => {
        // if (userActionAccess(ACTION_TYPE?.GET_SCAN_DTL)) {
        dispatch(getScanDetails(params?.scanId, clientOrgId));
        // }
      })
      .then(() => {
        // dispatch(getScanStageFlow(params?.scanId, clientOrgId));
        dispatch(getScanStatusFlow(params?.scanId, clientOrgId));
      });
  };
  const handleSubmitToSM = () => {
    dispatch(submitToSM(params?.scanId, clientOrgId))
      .then(() => {
        dispatch(getScanDetails(params?.scanId, clientOrgId));
      })
      .then(() => {
        dispatch(getScanStatusFlow(params?.scanId, clientOrgId));

        // dispatch(getScanStageFlow(params?.scanId, clientOrgId));
      });
  };
  const handleGoBack = () => {
    // if (locationState) {
    //   navigate(`/scan`);
    // } else {
    //   navigate(`/${locationPath}/dashboard`);
    // }
    navigate(-1);
  };
  const handleScanStatusChange = (description) => {
    const scanId = Number(params?.scanId);
    const clientOrgIdNumber = Number(clientOrgId);

    const fetchScanDetails = () => {
      dispatch(getScanDetails(scanId, clientOrgIdNumber));
      dispatch(getScanStatusFlow(scanId, clientOrgIdNumber));
    };

    if (selectedScanStatus) {
      const payload = {
        scanId,
        clientOrgId: clientOrgIdNumber,
        newStatus: selectedScanStatus,
        ...(description && { commentDesc: description }), // Add commentDesc only if description is present
      };

      dispatch(handleUpdateScanStatus(payload)).then((res) => {
        if (res?.status >= 200 && res?.status < 400) {
          fetchScanDetails();
          setOpenScanStatusConfirmDialog(false);
          setDescription("");
          setSelectedScanStatus();
        }
      });
    } else {
      // Send description only if it's present
      dispatch(
        publishScan(scanId, clientOrgIdNumber, description || undefined)
      ).then((response) => {
        if (response?.status >= 200 && response?.status < 400) {
          fetchScanDetails();
        }
        handleClose();
      });
    }
  };

  const handleSelectScanStatus = async (status) => {
    try {
      setSelectedScanStatus(status);
      setDescription("");

      if (status === SCAN_STATUS_CODE?.REVIEW || !status) {
        // Fetch the scan asset verification list
        const response = await dispatch(
          fetchScanAssetVerification({ scanId: params?.scanId, clientOrgId })
        );

        if (response?.data?.length > 0) {
          // Open Scan Asset Verification Dialog if data is present
          setOpenScanAssetVerificationDialog(!openScanAssetVerificationDialog);
        } else {
          // Open Scan Status Confirm Dialog if no data
          setOpenScanStatusConfirmDialog(!openScanStatusConfirmDialog);
        }
      } else {
        // Open Scan Status Confirm Dialog for other statuses
        setOpenScanStatusConfirmDialog(!openScanStatusConfirmDialog);
      }
    } catch (error) {
      console.error("Error while selecting scan status:", error);

    }
  };

  const handleCloseAssetLastScanVulnerabilityDialog = () => {
    setOpenScanAssetVerificationDialog(!openScanAssetVerificationDialog);
  };

  useEffect(() => {
    const convertToString = JSON.stringify(
      scanDetails?.displayVulnerabilityField
    );
    sessionStorage.setItem("displayVulnerabilityField", convertToString);
  }, [scanDetails]);

  useEffect(() => {
    dispatch(getScanStatusFlow(params?.scanId, clientOrgId));
  }, []);

  return (
    <Stack direction="column">
      <SubCard
        title="Scan Details"
        sx={{}}
        secondary={
          <>
            <Grid item>
              <>
                <Chip
                  size="small"
                  sx={{
                    mr: 2,
                    cursor: "pointer",
                  }}
                  icon={
                    <ArrowBackIosNewIcon
                      onClick={handleGoBack}
                      fontSize="small"
                      sx={{
                        color: "grey",
                      }}
                    />
                  }
                />
                <ConfirmChangeScanStatus
                  open={openScanStatusConfirmDialog}
                  handleClose={handleSelectScanStatus}
                  handleChangeStatusAction={handleScanStatusChange}
                  description={description}
                  setDescription={setDescription}
                  selectedScanStatus={selectedScanStatus}
                />

                <DialogBox
                  maxWidth="md"
                  modelTitle={"Assets With No Vulnerabilities "}
                  open={openScanAssetVerificationDialog}
                  dialogContent={
                    <AssetsLastScanDetail
                      setCofrimDialog={setOpenScanStatusConfirmDialog}
                      setOpenScanAssetVerificationDialog={
                        setOpenScanAssetVerificationDialog
                      }
                      handleClose={handleCloseAssetLastScanVulnerabilityDialog}
                      selectedScanStatus={selectedScanStatus}
                      scanId={params?.scanId}
                      clientOrgId={clientOrgId}
                    />
                  }
                />
              </>
              {showScanActionButton ? (
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleClick}
                  sx={{
                    boxShadow: theme.customShadows.primary,
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                  aria-label="actions"
                  endIcon={<ArrowDropDownIcon fontSize="small" />}
                >
                  Actions
                </Button>
              ) : (
                <></>
              )}

              <Menu
                id="menu-simple-card"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                variant="selectedMenu"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {/* {type === "manager" && (
                  <>
                    <MenuItem onClick={handleSubmitToTester}>
                      Assign To Tester
                    </MenuItem>
                    {scanDetails?.displayScanStatus?.showPublishScan ? (
                      <MenuItem onClick={handlePublishScan}>
                        Publish Scan
                      </MenuItem>
                    ) : (
                      <></>
                    )}
                  </>
                )} */}
                {/* {type !== "manager" && (
                  <MenuItem onClick={handleSubmitToSM}>
                    Submit To Security Manager
                  </MenuItem>
                )} */}
                {displayScanStatus?.markAsScheduled ? (
                  <MenuItem
                    onClick={() =>
                      handleSelectScanStatus(SCAN_STATUS_CODE?.SCHEDULED)
                    }
                  >
                    Schedule
                  </MenuItem>
                ) : (
                  ""
                )}
                {displayScanStatus?.markAsHold ? (
                  <MenuItem
                    onClick={() =>
                      handleSelectScanStatus(SCAN_STATUS_CODE?.HOLD)
                    }
                  >
                    Hold
                  </MenuItem>
                ) : (
                  ""
                )}
                {displayScanStatus?.markAsWIP ? (
                  <MenuItem
                    onClick={() =>
                      handleSelectScanStatus(SCAN_STATUS_CODE?.WIP)
                    }
                  >
                    Work In Progress
                  </MenuItem>
                ) : (
                  ""
                )}
                {displayScanStatus?.markAsAbort ? (
                  <MenuItem
                    onClick={() =>
                      handleSelectScanStatus(SCAN_STATUS_CODE?.ABORT)
                    }
                  >
                    Abort
                  </MenuItem>
                ) : (
                  ""
                )}
                {displayScanStatus?.markAsReviewRequested ? (
                  <MenuItem
                    onClick={() =>
                      handleSelectScanStatus(SCAN_STATUS_CODE?.REVIEW)
                    }
                  >
                    Review
                  </MenuItem>
                ) : (
                  ""
                )}
                {displayScanStatus?.markAsChangeRequested ? (
                  <MenuItem
                    onClick={() =>
                      handleSelectScanStatus(SCAN_STATUS_CODE?.CHANGE_REQUESTED)
                    }
                  >
                    Change Requested
                  </MenuItem>
                ) : (
                  ""
                )}
                {displayScanStatus?.publishScan ? (
                  <MenuItem onClick={() => handleSelectScanStatus(false)}>
                    Publish Scan
                  </MenuItem>
                ) : (
                  ""
                )}
              </Menu>
            </Grid>
          </>
        }
        //   [
        //     {
        //         "errorCode": "SCAN_REPORT_NOT_GENERATED",
        //         "errorMessage": "Scan Report in PDF format is not generated. PLease generate/upload the same."
        //     }
        // ]
      >
        <Grid
          // sx={{ p: 2 }}
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item>
            <Grid
              container
              direction="column"
              // spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography component="div" variant="subtitle2">
                  Scan Name:
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="div" variant="h3">
                  {scanDetails?.scanName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="column"
              // spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography component="div" variant="subtitle2">
                  Scan Ref Code:
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="div" variant="h3">
                  {scanDetails?.scanReferenceNo}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="column"
              // spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography component="div" variant="subtitle2">
                  Client Organization:
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="div" variant="h3">
                  {scanDetails?.clientOrgName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SubCard>

      <MainCard>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Tabs
              value={selectedScanDetailsTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="simple tabs example"
              variant="scrollable"
              sx={{
                mb: 0.5,
                "& a": {
                  minHeight: "auto",
                  minWidth: 10,
                  py: 1.5,
                  px: 1,
                  mr: 2.25,
                  color:
                    theme.palette.mode === "dark" ? "grey.600" : "grey.900",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                },
                "& a.Mui-selected": {
                  color: theme.palette.primary.main,
                },
                "& .MuiTabs-indicator": {
                  bottom: 2,
                },
                "& a > svg": {
                  marginBottom: "0px !important",
                  mr: 1.25,
                },
              }}
            >
              {tabsOption?.length &&
                tabsOption?.map((tab, index) => (
                  <Tab
                    key={index}
                    component={Link}
                    to="#"
                    label={tab.label}
                    {...a11yProps(index)}
                  />
                ))}
            </Tabs>

            <Box
              sx={{ maxHeight: "45vh", overflow: "auto", pr: 2, pb: 2 }}
              className="info-page"
            >
              <TabPanel value={selectedScanDetailsTab} index={0}>
                <ScanDetails scanDetails={scanDetails} />
              </TabPanel>
              <TabPanel value={selectedScanDetailsTab} index={1}>
                <ScanAssets
                  assetsForScan={assetsForScan}
                  scanDetails={scanDetails}
                />
              </TabPanel>
              <TabPanel value={selectedScanDetailsTab} index={2}>
                <ScanVulnerabilities
                  scanVulnerabilities={scanVulnerabilities}
                  scanDetails={scanDetails}
                />
              </TabPanel>
              {scanDetails?.testPlanTabBoolean && false && (
                <TabPanel value={selectedScanDetailsTab} index={3}>
                  <TestCase scanDetails={scanDetails} />
                </TabPanel>
              )}
              <TabPanel
                value={selectedScanDetailsTab}
                index={scanDetails?.testPlanTabBoolean && false ? 4 : 3}
              >
                <ReportConfiguration updateReportConfig={updateReportConfig} />
              </TabPanel>
              <TabPanel
                value={selectedScanDetailsTab}
                index={scanDetails?.testPlanTabBoolean && false ? 5 : 4}
              >
                <ReportHistory scanDetails={scanDetails} />
              </TabPanel>
              <TabPanel
                value={selectedScanDetailsTab}
                index={scanDetails?.testPlanTabBoolean && false ? 6 : 5}
              >
                <TimeLine
                  commentList={scanStatusFlow}
                  fieldsName={"scanStatusDesc"}
                  userName={"displayName"}
                />
              </TabPanel>
              <TabPanel
                value={selectedScanDetailsTab}
                index={scanDetails?.testPlanTabBoolean && false ? 7 : 6}
              >
                <Comments scanDetails={scanDetails} />
              </TabPanel>
            </Box>
          </Grid>
          {/* {publishError?.errors?.length > 0 ? (
            <DialogBox
              handleClose={() => setShowError(false)}
              maxWidth="sm"
              modelTitle={"Scan issues"}
              open={showError}
              dialogContent={<PublishError errorDetails={publishError} />}
            />
          ) : (
            ""
          )} */}
        </Grid>
      </MainCard>
    </Stack>
  );
};
export default ScanDetail;
