import { RISK_RATING } from "constant";

export function wrapGroupName(list, isTitle) {
  if (!list?.length > 0) {
    return null;
  }
  let wrappedTitle = "";
  if (isTitle && list?.length > 0) {
    return `${list[0]}${list?.length > 1 ? " " : ""}`;
  }
  for (let item of list) {
    wrappedTitle += item;
    wrappedTitle += "\n";
  }
  return wrappedTitle;
}

export function userActionAccess(actionType) {
  let defaulValue = true;
  const organizationType = localStorage.getItem("organizationType");
  const actions = localStorage.getItem("actions");
  const prefix = `${organizationType}_${actionType}`;
  if (!actions?.split(",").includes(prefix)) {
    defaulValue = false;
  }
  return defaulValue;
}

export function getChipDesign(riskLevel, theme) {
  // Declare variable for color only
  let color;

  // Set color based on riskLevel and theme
  switch (riskLevel) {
    case RISK_RATING.CRITICAL:
      color = theme === 'dark'
        ? RISK_RATING.CRITICAL_COLOR_DARK
        : RISK_RATING.CRITICAL_COLOR;
      break;

    case RISK_RATING.HIGH:
      color = theme === 'dark'
        ? RISK_RATING.HIGH_COLOR_DARK
        : RISK_RATING.HIGH_COLOR;
      break;

    case RISK_RATING.MEDIUM:
      color = theme === 'dark'
        ? RISK_RATING.MEDIUM_COLOR_DARK
        : RISK_RATING.MEDIUM_COLOR;
      break;

    case RISK_RATING.LOW:
      color = theme === 'dark'
        ? RISK_RATING.LOW_COLOR_DARK
        : RISK_RATING.LOW_COLOR;
      break;

    case RISK_RATING.INFO:
      color = theme === 'dark'
        ? RISK_RATING.INFO_COLOR_DARK
        : RISK_RATING.INFO_COLOR;
      break;

    default:
      // Handle default case if necessary
      return null; // Or return a default color
  }

  // Return the calculated color
  return {
    color: color,
  };
}


export function handleTrim(text) {
  if (text && text?.trim() !== "") {
    return true;
  } else {
    return false;
  }
}

export function handleUserRoles(rolesList, role) {
  if (rolesList?.length > 0) {
    return rolesList?.includes(role) || false;
  } else {
    return true
  }
}

export function convertNullToZero(number) {
  return number ?? 0;
}

export function divideData(rowCheck, data) {
  if (data?.length <= 6) {
    return rowCheck ? [] : [...data];
  }
  const { firstRow, secondRow } = data.reduce(
    (rows, value, index) => {
      if (index > 5) {
        rows.firstRow.push(value);
      } else {
        rows.secondRow.push(value);
      }
      return rows;
    },
    { firstRow: [], secondRow: [] }
  );

  return rowCheck ? firstRow : secondRow;
}

export const downloadTemplateFile = (fileData, fileName) => {
  // Decode the base64 string into a byte array
  if (fileData && fileName) {
    const byteArray = atob(fileData)
      .split("")
      ?.map((s) => s?.charCodeAt(0));

    // Create the Blob object with the correct MIME type (adjust if needed)
    const blob = new Blob([new Uint8Array(byteArray)], {
      type: "application/octet-stream",
    });

    const url = URL?.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL?.revokeObjectURL(url);
  }
};
