import { Suspense, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";

// routing
import router from "routes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import Locales from "ui-component/Locales";
import RTLLayout from "ui-component/RTLLayout";
import Snackbar from "ui-component/extended/Snackbar";

import ThemeCustomization from "themes";

// auth provider
import { JWTProvider as AuthProvider } from "contexts/JWTContext";
import GlobalLoader from "views/Common/GlobalLoader";

// ==============================|| APP ||============================== //

const App = () => {
  const [currentVersion, setCurrentVersion] = useState(null);
  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response = await fetch("/version.json", { cache: "no-store" });
        console.log("currentVersionUesEffect", currentVersion);

        const data = await response.json();

        if (currentVersion && data.version !== currentVersion) {
          // New version detected
          alert("New version available. The page will refresh.");
          window.location.reload();
        }

        setCurrentVersion(data.version);
      } catch (error) {
        console.error("Error fetching version:", error);
      }
    };

    // Check every 5 minutes
    const interval = setInterval(checkVersion, 5 * 60 * 1000);
    checkVersion();

    return () => clearInterval(interval);
  }, [currentVersion]);
  console.log("currentVersion", currentVersion);
  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <NavigationScroll>
            <AuthProvider>
              <>
                <GlobalLoader />
                <Suspense fallback={<div>Loading...</div>}>
                  <RouterProvider router={router} />
                </Suspense>
                <Snackbar />
                <Toaster position="top-center" reverseOrder={false} />
              </>
            </AuthProvider>
          </NavigationScroll>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
